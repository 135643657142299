<template>
    <div class="online-apply">
        <div class="title">
            <img src="../assets/images/online-title-bg.png" alt="">
        </div>
        <div class="slider-wrappr">
            <slider animation="fade" :indicators="false" width="1163px" height="625px">
                <slider-item>
                    <img src='../assets/images/ex-img1.png' />
                </slider-item>
                <slider-item>
                    <img src='../assets/images/ex-img2.png' />
                </slider-item>
                <slider-item>
                    <img src='../assets/images/ex-img3.png' />
                </slider-item>
            </slider>
        </div>
        <div class="btm-section">
            <img src="../assets/images/online-btm-txt.png" alt="">
        </div>
    </div>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'
export default {
    components: {
        Slider,
        SliderItem
    }
}
</script>

<style>
    .online-apply{
        padding-top: 50px;
        padding-bottom: 65px;
        background-color: #171717;
        height: 100vh;
    }
    .slider-wrappr{
        margin: 50px auto 0;
        width: 1163px;
        height: 625px;
    }
    .slider .slider-items{
        width: 1023px!important;
        margin: 0 auto;
        position: relative;
    }
    .slider .slider-icon{
        border-width: 4px!important;
        width: 30px!important;
        height: 30px!important;
    }
    .btm-section{
        width: 100%;
        height: 100px;
        margin-top: 50px;
        text-align: center;
        background-color:rgba(255,202,143,.4);
    }
    .btm-section img{
        margin-top: 30px;
    }
</style>